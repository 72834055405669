import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=e3e81e82&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('C:/Projects/mpc-nuxt-master/v8/nuxt/components/Footer.vue').default})
